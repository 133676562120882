import Vue from 'vue'
import {EagleListConfigInterface, TableDataType} from '@/components/list/types/list'
import memberListConfig from 'modules/member/views/memberListConfig'
class listConfig {
  protected vm?: Vue
  protected listKey?: string
  protected standardMemberListConfig?: EagleListConfigInterface

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.standardMemberListConfig = memberListConfig.get(vm, listKey)
    this.vm = vm
    this.listKey = listKey

    return {
      search: this.standardMemberListConfig.search,
      filter: this.standardMemberListConfig.filter,
      selectedData: this.standardMemberListConfig.selectedData,
      sort: this.standardMemberListConfig.sort,
      table: {
        data: <TableDataType>[
          {
            key: 'member',
            label: 'module.member',
            text: row => this.vm?.$helper.getSiteUserName(row),
          },
          {
            key: 'email',
            label: 'data.email',
            type: 'text',
          },
          {
            key: 'register_type',
            label: 'member.data.register_type',
            type: 'text',
            text: row => this.vm?.$t(`sso.${row.register_type}`),
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
    }

  }
}

export default new listConfig()
